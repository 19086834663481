<template>
  <div>
    <!--/ avatar -->
    <b-link @click="$refs.refInputEl.click()">
      <!-- <b-img
        ref="previewEl"
        v-b-tooltip.hover.bottom="$t(tooltipText)"
        thumbnail
        :src="avatarX"
        :size="imageSize"
        :class="avatarX ? 'bg-white i-shadow-new': 'bg-dark i-shadow-new' "
      >
        <span v-if="!avatarX">+</span>
        <i
          v-if="!avatarX"
          :class="`${avatarIcon} i-icon-size-avatar`"
        />
      </b-img> -->
      <b-img
        ref="previewEl"
        v-b-tooltip.hover.bottom="$t(tooltipText)"
        :src="avatarX"
        height="110"
        width="170"
        class="rounded mr-2 mb-1 mb-md-0"
      >
        <span v-if="!avatarX">+</span>
        <i
          v-if="!avatarX"
          :class="`${avatarIcon} i-icon-size-avatar`"
        />
      </b-img>
    </b-link>
    <!--/ avatar -->
    <p class="mt-1">
      <!-- upload button -->
      <b-link
        v-if="applyBLink"
        v-b-tooltip.hover.bottom="$t('generic.allowedFormat')"
        class="text-dark i-link-avatar"
        size="sm"
        @click="$refs.refInputEl.click()"
      >
        {{ $t(buttonText) }}
      </b-link>
      <!--/ upload button -->
      <!-- file -->
      <input
        ref="refInputEl"
        type="file"
        class="d-none"
        accept=".jpg, .png, .gif"
        :disabled="disabled"
        @input="inputImageRenderer"
      >
      <!--/ file -->
    </p>
  </div>
</template>

<script>
import {
  BLink, VBTooltip,
} from 'bootstrap-vue'

// import { ref } from '@vue/composition-api'
import { ref, watch, computed } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    tooltipText: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    avatarIcon: {
      type: String,
      default: null,
    },
    imageSize: {
      type: String,
      default: null,
    },
    applyBLink: {
      type: Boolean,
      default: false,
    },
    pathFileAvatar: {
      type: [String, File],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const avatarX = ref(null)
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      avatarX.value = base64

      emit('intance-file-x', refInputEl.value.files[0])
    })

    const clubLogo = computed(() => props.pathFileAvatar)

    watch([clubLogo], val => {
      if (val[0] && typeof props.pathFileAvatar === 'string') {
        avatarX.value = props.pathFileAvatar
      }
    })

    return {
      avatarX,
      refInputEl,
      previewEl,
      inputImageRenderer,

    }
  },
}
</script>

<style scoped>
.i-icon-size-avatar {
  font-size: 30px;
}

.i-link-avatar:hover {
  font-weight: 600;
}
</style>
