<template>
  <b-card
    v-if="Object.keys(notice).length"
    class="blog-edit-wrapper"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit, invalid }"
      ref="refFormNewObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <!-- Filter -->
          <!-- :description="shareDescription" -->
          <template v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <b-col
              v-if="!isClubOwned()"
              cols="6"
            >
              <label for="blog-edit-show">{{ $t('post-to-all-clubs') }} <span class="text-danger">*</span> </label>
              <b-form-group>
                <label for="showAllClub">{{ $t('generic.not') }}</label>
                <b-form-checkbox
                  id="showAllClub"
                  v-model="notice.show_all_clubs"
                  :checked="notice.show_all_clubs"
                  :value="true"
                  :unchecked-value="false"
                  name="check-button"
                  class="ml-1"
                  switch
                  inline
                >
                  {{ $t('generic.yes') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- country -->
            <b-col
              v-if="!notice.show_all_clubs && !isClubOwned()"
              md="6"
            >

              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <b-form-group
                  class="mb-2"
                >
                  <label for="blog-edit-country">{{ $t('generic.country') }} <span class="text-danger">*</span> </label>
                  <v-select
                    id="blog-edit-country"
                    v-model="notice.country_hash"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countries"
                    :reduce="(item) => item.id"
                    :placeholder="$t('generic.selectCountry')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </template>
          <div class="w-100" />
          <b-col md="6">

            <!-- Title -->
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required|max:254|alphaNumSpacesRegex"
            >
              <b-form-group
                class="mb-2"
              >
                <label for="blog-edit-title">{{ $t('generic.title') }} <span class="text-danger">*</span> </label>
                <b-form-input
                  id="blog-edit-title"
                  v-model="notice.title"
                  :placeholder="$t('generic.titleNew')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col md="6">

            <!-- tags -->
            <validation-provider
              #default="{ errors }"
              name="tags"
              rules="required|max:254"
            >
              <b-form-group
                class="mb-2"
                :state="state"
              >
                <label for="blog-edit-tags">{{ $t('generic.tags') }} <span class="text-danger">*</span> </label>
                <b-form-tags
                  v-model="notice.tags"
                  :placeholder="$t('generic.enterTags')"
                  :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                  :tag-validator="tagValidator"
                  :state="state"
                  :limit="limit"
                  separator=" "
                  :add-button-text="$t('Add')"
                  input-id="blog-edit-tags"
                />
                <template #description>
                  <div id="tags-validation-help">
                    {{ $t('description-tags') }}
                  </div>
                </template>
                <!-- <v-select
                  id="blog-edit-tags"
                  v-model="notice.tags"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  no-drop
                  taggable
                  multiple
                  :select-on-key-codes="[13, 188]"
                  :placeholder="$t('generic.enterTags')"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Location -->
          <b-col md="6">

            <!-- tags -->
            <validation-provider
              #default="{ errors }"
              name="language"
              rules="required"
            >
              <b-form-group
                class="mb-2"
              >
                <label for="blog-edit-location">{{ $t('generic.language') }} <span class="text-danger">*</span> </label>
                <v-select
                  id="blog-edit-location"
                  v-model="notice.location"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="localesOption"
                  :reduce="(item) => item.locale"
                  :placeholder="$t('generic.selectLanguage')"
                  :state="errors.length > 0 ? false:null"
                >
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">

            <!-- Status -->
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <b-form-group
                class="mb-2"
              >
                <label for="blog-edit-status">{{ $t('generic.status') }} <span class="text-danger">*</span> </label>
                <v-select
                  id="blog-edit-status"
                  v-model="notice.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusesOption"
                  label="key"
                  :reduce="(item) => item.value"
                  :placeholder="$t('generic.selectStatus')"
                >
                  <template #option="{ key }">
                    <span> {{ $t(key) }}</span>
                  </template>

                  <template #selected-option="{ key }">
                    <span class="align-middle"> {{ $t(key) }}</span>
                  </template>
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Date -->
          <b-col md="3">

            <!-- Date -->
            <validation-provider
              #default="{ errors }"
              name="initDate"
              rules="required"
            >
              <b-form-group
                :label="$t('generic.datePublished')"
                label-for="blog-edit-published"
                class="mb-2"
              >
                <b-form-datepicker
                  id="blog-edit-published"
                  v-model="notice.init_publication_date"
                  :locale="$i18n.locale"
                  :placeholder="$t('generic.initDate')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Date -->
          <b-col md="3">
            <b-form-group
              label-for="blog-edit-publication"
              class="mb-2"
            >
              <label />
              <b-form-datepicker
                id="blog-edit-publication"
                v-model="notice.end_publication_date"
                :locale="$i18n.locale"
                :min="notice.init_publication_date"
                :disabled="notice.init_publication_date == null"
                :placeholder="$t('generic.endDate')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :description="shareDescription"
            >
              <label for="postShare">{{ $t('private') }} </label>
              <b-form-checkbox
                id="postShare"
                v-model="notice.share_post"
                :checked="notice.share_post"
                :value="true"
                :unchecked-value="false"
                name="check-button"
                class="ml-1"
                switch
                inline
              >
                {{ $t('public') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                {{ $t('generic.featuredImage') }}
              </h4>
              <!-- {{notice.image}} -->
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="text-center">
                  <upload-image
                    :tooltip-text="'generic.allowedFormat'"
                    :button-text="'generic.uploadPhoto'"
                    :avatar-icon="'icon-0-icons-dark-avatar'"
                    :image-size="'10rem'"
                    :apply-b-link="true"
                    :path-file-avatar="notice.image ? notice.image.url_thumb : ''"
                    @intance-file-x="fileValidation($event, 'image')"
                  />
                </div>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('generic.description')"
              label-for="blog-content"
              class="mb-2 pb-5"
            >
              <quill-editor
                id="editor-description"
                v-model="notice.description"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                {{ $t('main-content-file') }}
              </h4>
              <b-media
                vertical-align="center"
              >
                <!-- no-body -->
                <!-- class="flex-column flex-md-row" -->
                <div>
                  <b-row>
                    <b-col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                    >
                      <!-- Status -->
                      <validation-provider
                        #default="{ errors }"
                        name="status"
                        rules="required"
                      >
                        <b-form-group
                          class="mb-2"
                        >
                          <label for="blog-edit-status">{{ $t('type-of-file') }} <span class="text-danger">*</span> </label>
                          <v-select
                            id="blog-edit-status"
                            v-model="notice.head_content_type_key"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="contentOption"
                            label="key"
                            :reduce="(item) => item.key"
                            :placeholder="$t('generic.selectStatus')"
                          >
                            <template #option="{ key }">
                              <span> {{ $t(key) }}</span>
                            </template>

                            <template #selected-option="{ key }">
                              <span class="align-middle"> {{ $t(key) }}</span>
                            </template>
                            <div slot="no-options">
                              {{ $t('generic.noRecordsFound') }}
                            </div>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <div class="w-100" />
                    <b-col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                    >
                      <b-media-body>
                        <div v-if="notice.head_content_type_key == 'FILE'">
                          <!-- <small class="text-muted">{{ $t('generic.requiredImage800x400') }}</small><br> -->
                          <upload-file
                            :title="$t('generic.document')"
                            :file-title="notice.head_content ? notice.head_content.title : ''"
                            folder="notices"
                            :club-id="clubInfo ? clubInfo.hash : null"
                            @uploaded-file="assignFile"
                          />
                        </div>
                        <div v-if="notice.head_content_type_key == 'URL'">
                          <!-- URL -->
                          <validation-provider
                            #default="{ errors }"
                            name="url"
                            rules="required|url"
                          >
                            <b-form-group
                              class="mb-2"
                            >
                              <label for="blog-edit-head-url">{{ $t('generic.url') }} <span class="text-danger">*</span> </label>
                              <b-form-input
                                id="blog-edit-head-url"
                                v-model="notice.head_content_url"
                                :placeholder="$t('generic.enterUrl')"
                                autocomplete="off"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </b-media-body>
                    </b-col>
                  </b-row>
                </div>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('generic.content')"
              label-for="blog-content"
              class="mb-2 pb-5"
            >
              <quill-editor
                id="editor-container"
                v-model="notice.content"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <div class="btn-footer-modal">

              <!-- Cancelar -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="secondary"
                class="mr-1"
                pill
                @click="
                  $router.push({
                    name: 'news',
                  })
                "
              >
                {{ $t('buttons.cancel') }}
              </b-button>

              <!-- Guardar -->
              <overlay-button :loading="btnLoading">
                <b-button
                  v-if="checkPermission(['CREATE_NOTICES', 'EDIT_NOTICES', 'ALLOW_ALL'])"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  pill
                  class="mr-1"
                  type="submit"
                  :disabled="invalid"
                  :style="colorPrimaryBtn"
                >
                  {{ notice.hash ? $t('generic.upload') : $t('buttons.save') }}
                </b-button>
              </overlay-button>
            </div>
            <!-- Eliminar -->
            <overlay-button
              :loading="btnDelete"
              class="btn-delete-footer float-right"
            >
              <b-button
                v-if="notice.hash && checkPermission(['DELETE_NOTICES', 'ALLOW_ALL'])"
                variant="outline-secondary"
                class="mr-1 btn-delete-footer float-right"
                pill
                @click="onRemoveNew(notice)"
              >
                <i class="icon-0-icons-dark-trash-can mr-25" />
                <span class="align-middle">{{ $t('buttons.delete') }}</span>
              </b-button>
            </overlay-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import checkPermission from '@/auth/permissions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import UploadFile from '@/views/member/components/UploadFile.vue'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import { ref } from '@vue/composition-api'
import { locales, statusesNotice, contentNotice } from '@core/utils/catalogStatic'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import UploadImage from '../components/UploadImageAvatar.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    UploadFile,
    OverlayButton,
    UploadImage,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // blogFile: null,
      limit: 5,
      dirty: false,
      btnDelete: false,
      btnLoading: false,
      shareDescription: this.$t('description-post-to-share'),
      localesOption: locales,
      statusesOption: statusesNotice,
      contentOption: contentNotice,
      // tagsOption: ['Deporte', 'Rendimiento', 'Estrategia'],
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('insert-text'),
      },
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries',
      notice: 'noticeStore/notice',
      colorPrimaryBtn: 'colorPrimaryBtn',
      clubInfo: 'clubInfo',
    }),
    state() {
      return this.dirty ? (this.notice.tags.length > 2 && this.notice.tags.length < 18) : null
    },
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchCountries()
    },
    'notice.share_post': function objNewSharePost(option) {
      if (option === true) {
        this.shareDescription = this.$t('description-post-do-not-share')
        return
      }
      this.shareDescription = this.$t('description-post-to-share')
    },
    'notice.show_all_clubs': function noticeshowclub(val) {
      if (val) {
        this.notice.country_hash = null
      }
    },
    'notice.tags': function noticeTags() {
      this.dirty = true
    },
  },
  created() {
    if ((this.$route.params.id != null && this.checkPermission(['EDIT_NOTICES'])) || (this.$route.params.id == null && this.checkPermission(['CREATE_NOTICES']))) {
      this.resetForm()
      this.fetchCountries()
      if (this.$route.params.id != null) {
        this.getNoticeById()
      }
      // this.fechaDataNewStatus()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    validatorFileSize,
    validatorFileType,
    ...mapActions({
      fetchCountries: 'fetchCountries',
      fetchDataNoticeById: 'noticeStore/fetchDataById',
      updateNotice: 'noticeStore/update',
      removeNotice: 'noticeStore/remove',
      addNotice: 'noticeStore/add',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
    }),
    tagValidator(tag) {
      // Individual tag validator function
      return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 18
    },
    async getNoticeById() {
      await this.fetchDataNoticeById(this.$route.params.id).then(response => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    onSubmit() {
      const objNewData = JSON.parse(JSON.stringify(this.notice))
      objNewData.owned_by = 'OSTRAIL'
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        objNewData.club_hash = this.clubInfo.hash
        objNewData.owned_by = 'CLUB'
      }

      if (objNewData.hash) {
        this.onUpdateNew(objNewData)
      } else {
        this.onAddNew(objNewData)
      }
    },
    async onAddNew(notice) {
      this.btnLoading = true
      this.$refs.refFormNewObserver.validate().then(async isValid => {
        if (isValid) {
          await this.addNotice(notice).then(response => {
            this.responseSuccessCreate(response.data.message)
            this.btnLoading = false
            this.resetForm()
            this.$router.push({ name: 'news' })
          }).catch(error => {
            this.btnLoading = false
            this.responseCatch(error)
          })
          // this.btnLoading = false
        }
      })
    },
    async onUpdateNew(notice) {
      this.btnLoading = true
      // notice.owned_by = 'OSTRAIL'
      if (this.isClubOwned()) {
        // notice.club_hash = this.clubInfo.hash
        notice.owned_by = 'CLUB'
      }
      this.$refs.refFormNewObserver.validate().then(async isValid => {
        if (isValid) {
          await this.updateNotice(notice).then(response => {
            this.responseSuccessUpdate(response.data.message)
            this.resetForm()
            this.btnLoading = false
            this.$router.push({ name: 'news' })
          }).catch(error => {
            this.btnLoading = false
            this.responseCatch(error)
          })
        }
      })
      // this.btnLoading = false
    },
    async onRemoveNew(notice) {
      this.btnDelete = true
      await this.sampleDeleteQuestion()
        .then(async result => {
          if (!result.isConfirmed) {
            this.btnDelete = false
            return
          }
          await this.removeNotice(notice.hash).then(response => {
            this.btnDelete = false
            this.responseSuccessElimination(response.data.message)
            this.$router.push({ name: 'news' })
          })
        }).catch(error => {
          this.btnDelete = false
          this.responseCatch(error)
        })
    },
    assignFile(responseFile) {
      const previousFileHash = this.notice.head_content_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.notice, 'head_content', responseFile)
      this.$set(this.notice, 'head_content_media_file_hash', responseFile.hash)
    },
    async fileValidation(fileData, typeField) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   if (typeField === 'image') this.notice.image_media_file_hash = null
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        if (typeField === 'image') this.notice.image_media_file_hash = null

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      this.saveFileUpload(fileData, typeField)
    },
    async saveFileUpload(fileData, typeField) {
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', 'notices')
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        formData.append('club_id', this.clubInfo.hash)
      }
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        if (typeField === 'image') {
          const previousFileHash = this.notice.image_media_file_hash
          if (previousFileHash != null) {
            this.mediaFileDestroy(previousFileHash)
              .then(() => {
              }).catch(error => {
                this.responseCatch(error)
              })
          }
          this.$set(this.notice, 'image', data)
          this.$set(this.notice, 'image_media_file_hash', data.hash)
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    isClubOwned() {
      if ((this.notice.show_all_clubs === false && this.notice.country_hash == null) && (this.notice.hash != null && this.notice.owned_by === 'CLUB')) {
        return true
      }
      return false
    },
    resetForm() {
      this.notice.hash = null
      this.notice.title = null
      this.notice.tags = null
      this.notice.description = null
      this.notice.slug = null
      this.notice.content = null
      this.notice.status = false
      this.notice.location = null
      this.notice.init_publication_date = null
      this.notice.end_publication_date = null
      this.notice.share_post = false
      this.notice.head_content_type_key = null
      this.notice.head_content_url = null
      this.notice.image = null
      this.notice.image_media_file_hash = null
      this.notice.head_content = null
      this.notice.head_content_media_file_hash = null
      this.notice.club = null
      this.notice.club_hash = null
      this.notice.user = null
      this.notice.show_all_clubs = false
      this.notice.country_hash = null
      this.notice.country = null
      this.notice.owned_by = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

#editor-description {
  height: 150px;
}
#editor-container {
  height: 350px;
}

#div-container-rt {
  height: 395px;
}

@media (max-width: 768px) {
  #div-container-rt {
    height: 420px;
  }
}

@media (max-width: 576px) {
  #div-container-rt {
    height: 515px;
  }
}

@media (max-width: 320px) {
  #div-container-rt {
    height: 550px;
  }
}
.btn-footer-modal {
    float: right
  }
@media (max-width: 450px) {
  .btn-footer-modal,
  .btn-delete-footer {
    float: none !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .btn-delete-footer {
    margin-top: 30px;
    width: 95%;
  }
}
</style>
